<template>
  <TTView>
    <VCard :loading="loadingData">
      <VContainer>
        <VRow>
          <VCol cols="auto">
            <span class="tt-text-headline-2">Добавить связь между ШД и ГК</span>
          </VCol>
        </VRow>
        <VForm
          ref="form"
          v-model="valid"
          class="my-3"
          lazy-validation
        >
          <VRow>
            <VCol cols="12">
              <TTAutocomplete
                v-model="fromStaffPositionId"
                :items="staffPositions"
                :rules="staffPositionsRules"
                label="От штатной должности"
                :filter="searchFilter"
                item-value="id"
                item-text="name"
                :disabled="!!fromStaffPosition || !!toCompetenciesGroupId"
                :search-input.sync="positionsSearchQuery"
                @update:search-input="searchStaffPositions"
                @input="searchStaffPositions"
              >
                <template #selection="{ item }">
                  <span
                    class="tt-text-body-2 ml-3"
                    v-text="item.id"
                  />
                  &nbsp;|&nbsp;
                  <span
                    class="tt-text-body-2"
                    v-text="item.name"
                  />
                </template>

                <template #item="{ item }">
                  <div>
                    <div
                      class="tt-text-body-1"
                      v-text="item.name"
                    />
                    <div
                      class="tt-text-body-2"
                      v-text="item.id"
                    />
                  </div>
                </template>
              </TTAutocomplete>
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12">
              <TTAutocomplete
                v-model="toCompetenciesGroupId"
                :items="filteredCompetenciesGroups"
                :rules="competenciesGroupRules"
                label="К группе компетенций"
                item-value="id"
                item-text="name"
                :filter="searchFilter"
                :disabled="!fromStaffPositionId"
                @update:search-input="searchCompetenciesGroupsByCompany($event)"
                @input="searchCompetenciesGroupsByCompany($event)"
              >
                <template #selection="{ item }">
                  <span
                    class="tt-text-body-2 ml-3"
                    v-text="item.id"
                  />
                  &nbsp;|&nbsp;
                  <span
                    class="tt-text-body-2"
                    v-text="item.name"
                  />
                </template>

                <template #item="{ item }">
                  <div>
                    <div
                      class="tt-text-body-1"
                      v-text="item.name"
                    />
                    <div
                      class="tt-text-body-2"
                      v-text="item.id"
                    />
                  </div>
                </template>
              </TTAutocomplete>
            </VCol>
          </VRow>
        </VForm>
        <VRow>
          <VCol cols="auto">
            <TTBtn
              v-if="isComingFromOrgUserCompany"
              color="tt-secondary"
              @click="$router.go(-1)"
            >
              Назад
            </TTBtn>
          </VCol>
          <VSpacer />
          <VCol cols="auto">
            <TTBtn
              color="primary"
              :disable="!valid || loadingData"
              :loading="loading"
              @click="createCompetenciesGroupLink"
            >
              Добавить
            </TTBtn>
          </VCol>
        </VRow>
      </VContainer>
    </VCard>
  </TTView>
</template>

<script>
// TODO: временное решение https://talenttechgroup.slack.com/archives/C013F4E0DPF/p1629270318390400

export default {
  name: 'OrgPositionGroupLink',

  data() {
    return {
      valid: true,
      selectedStaffPosition: null,
      toCompetenciesGroupId: null,
      fromStaffPositionId: null,
      staffPositionCompanyId: null,
      competenciesGroups: [],
      loading: false,
      loadingData: false,
      loadingStaffPositions: false,
      staffPositionsRules: [
        (v) => !!v || 'Выберите штатную должность',
      ],
      competenciesGroupRules: [
        (v) => !!v || 'Выберите группу компетенций',
      ],
      staffPositions: [],
      positionsSearchQuery: '',
    };
  },

  computed: {
    fromStaffPosition() {
      if (this.staffPositionId) {
        return this.staffPositions.find((sp) => sp.id === this.staffPositionId);
      }
      return null;
    },
    staffPositionId() {
      return this.$route.query.staffPositionId;
    },
    isComingFromOrgUserCompany() {
      return this.$route.params.staffPositionId;
    },
    filteredCompetenciesGroups() {
      return this.competenciesGroups.filter((el) => !this.selectedStaffPosition.competencyGroupIds.includes(el.id));
    },
  },

  watch: {
    async fromStaffPosition(val) {
      this.fromStaffPositionId = val.id;
    },
    async fromStaffPositionId(val) {
      await this.findSelectedStaffPosition(val);
      await this.searchCompetenciesGroupsByCompany();
    },
    staffPositionId: {
      handler(val) {
        if (val) {
          this.searchStaffPositions(val);
        }
      },
      immediate: true,
    },
  },

  async created() {
    try {
      this.loadingData = true;
      const { staffPositions = [] } = await this.$di.api.Orgstructure.searchStaffPositions({
        query: '',
      });
      this.staffPositions = staffPositions;
    } catch (err) {
      this.$di.notify.errorHandler(err);
    } finally {
      this.loadingData = false;
    }
  },

  methods: {
    async findSelectedStaffPosition(val) {
      if (val) {
        const { staffPositions } = await this.$di.api.Orgstructure.staffPositionsByIds({ ids: [val] });
        this.selectedStaffPosition = staffPositions.find((el) => el.id === val);
        this.staffPositionCompanyId = this.selectedStaffPosition?.companyId;
      }
    },
    async searchCompetenciesGroupsByCompany(value) {
      const companyResponse = await this.$di.api.CompetencyMatrix.SearchCompetencyGroupsByCompany({
        query: value,
        companyId: this.staffPositionCompanyId,
      });

      this.competenciesGroups = companyResponse.competencyGroups;
    },
    async searchStaffPositions(val = '') {
      const { staffPositions = [] } = await this.$di.api.Orgstructure.searchStaffPositions({
        query: val || this.positionsSearchQuery,
      });

      this.staffPositions = staffPositions;
    },
    searchFilter(item, queryText) {
      const name = item.name.toLowerCase();
      const uuid = item.id.toLowerCase();
      const searchText = queryText.toLowerCase();

      return name.indexOf(searchText) > -1 || uuid.indexOf(searchText) > -1;
    },
    async createCompetenciesGroupLink() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const { selectedStaffPosition, toCompetenciesGroupId } = this;

        try {
          await this.$di.api.Orgstructure.positionUpdateV2({
            ...selectedStaffPosition,
            competencyGroupIds: [
              ...selectedStaffPosition.competencyGroupIds,
              toCompetenciesGroupId,
            ],
          });
          this.$di.notify.snackSuccess('Связь добавлена');
          await this.findSelectedStaffPosition(this.fromStaffPositionId);
          this.clearForm();
        } catch (err) {
          this.$di.notify.errorHandler(err);
        } finally {
          this.loading = false;
        }
      }
    },
    clearForm() {
      this.toCompetenciesGroupId = null;
      this.searchCompetenciesGroupsByCompany();
      if (!this.fromStaffPosition) {
        this.fromStaffPositionId = null;
      }
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style scoped>
/* HOTFIX */
::v-deep .v-select.v-text-field input {
  position: relative;
}
</style>
